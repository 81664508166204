<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            取消订单
        </div>
        <div class="vip-content-inner">
            <div class="rich-text">
                <p>1、首先，在我的淘宝界面中点击更多订单或者待收货。 2、然后，找到需要退款的产品，然后单击进入。 3、打开后，找到红色箭头指向的“退换”，然后单击打开。 4、接着，在弹出窗口中，选择仅显示退款或退货退款。以退货退款申请为例，单击进入退款界面。 5、接着，选择退货原因。填写退款金额和退换说明，可以看到此处退款说明为选填，根据实际填写即可，然后单击提交。</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Cancel"
}
</script>

<style scoped>

</style>
